<template>
  <div>
    <h1 class="mb-4">Groups</h1>
    <v-data-table
      j
      :headers="headers"
      :items="currentIntel.groups.items"
      :items-per-page="10"
      sort-by="title"
      outlined
      tile
    >
      <template v-slot:item.advisors="{ item }">
        {{ item.advisors.items.length }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          text
          color="primary"
          :to="{
            name: 'IntelDetail',
            params: { id: item.id },
            query: { level: 'group' },
          }"
          >View Detail</v-btn
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      headers: [
        {
          text: "Title",
          align: "left",
          sortable: true,
          value: "title",
        },
        {
          text: "Advisors",
          align: "center",
          sortable: true,
          value: "advisors",
        },
        {
          text: "Actions",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapState(["currentIntel"]),
  },
  created() {
    console.log("groups", this.currentIntel.groups.items);
  },
};
</script>

<style lang="scss" scoped></style>
