var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mb-4"},[_vm._v("Groups")]),_c('v-data-table',{attrs:{"j":"","headers":_vm.headers,"items":_vm.currentIntel.groups.items,"items-per-page":10,"sort-by":"title","outlined":"","tile":""},scopedSlots:_vm._u([{key:"item.advisors",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.advisors.items.length)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":"","color":"primary","to":{
          name: 'IntelDetail',
          params: { id: item.id },
          query: { level: 'group' },
        }}},[_vm._v("View Detail")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }